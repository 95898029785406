//Modify this file to change the colors of the app

export const COLORS = Object.freeze({
  BLACK: "#2b2b2b",
  GREY: "#9a9391",
  GREY_STRONG: "#4D4D4D",
  RED: "#da5b00",
  YELLOW: "#d6a352",
  TEXT_PRIMARY: "#2b2b2b",
  MAIN: "#b7418e",
  MAIN_LIGHT: "#444",
  WHITE: "#fff",
});
