import React from "react";
import { motion } from "framer-motion";

const AnimatedSection = ({ children }) => {
  // Tres variantes de animaciones
  const animations = [
    {
      hidden: { opacity: 0, y: 50 },
      visible: { opacity: 1, y: 0 },
    },
    {
      hidden: { opacity: 0, y: 100 },
      visible: { opacity: 1, y: 0 },
    },
    {
      hidden: { opacity: 0, scale: 0.8 },
      visible: { opacity: 1, scale: 1 },
    },
  ];

  // Seleccionar aleatoriamente una animación
  const randomAnimation =
    animations[Math.floor(Math.random() * animations.length)];

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, amount: 0.3 }} // Animar al entrar al viewport
      transition={{ duration: 0.8, ease: "easeOut" }}
      variants={randomAnimation}
      style={{ width: "100%", margin: "50px 0" }}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedSection;
