import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import styles from "./styles.module.scss";
import { FlexColumn, FlexRow } from "../../../view/shared/base/FlexBox";
import LoadingIndicator from "../../../view/shared/base/LoadingIndicator";
import Text from "../../../view/shared/base/Text";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import useInvitado from "../../../hooks/useInvitado";
import MainTitle from "../../shared/base/MainTitle";

let cx = classNames.bind(styles);

const PageDashboard = () => {
  const {
    loading,
    allInvitados,
    totalPases,
    totalPasesUsados,
    totalRespondido,
    totalRechazado,
    getAllInvitados,
  } = useInvitado();

  useEffect(() => {
    getAllInvitados();
  }, []);

  const percentagePases = Math.round((totalPasesUsados / totalPases) * 100);
  const percentageRespondidos = Math.round(
    (totalRespondido / allInvitados.length) * 100
  );

  const getBackGroundColor = (index, confirma) => {
    if (confirma === "1") {
      if (index % 2 === 0) {
        return "#f4aa7c";
      } else {
        return "#F4E0D2";
      }
    }
    if (confirma === "0") {
      return "#d37878";
    }
    if (index % 2 === 0) {
      return "white";
    } else {
      return "#f9f9f9";
    }
  };

  if (loading) return <LoadingIndicator />;

  const ChartData = ({ label, percentage }) => {
    return (
      <FlexColumn
        fullWidth
        aiCenter
        jcCenter
        gap={5}
        m={5}
        p={5}
        sx={{
          minWidth: "200px",
          maxWidth: "300px",
          backgroundColor: "#F9F9F9",
          borderRadius: "10px",
        }}
      >
        <Text h3 colorTextSecondary>
          {label}
        </Text>
        <CircularProgressbar
          value={percentage}
          text={`${percentage}%`}
          styles={buildStyles({
            textSize: "16px",
            pathColor: "#DA5B01",
            trailColor: "#f4e0d2",
            textColor: "#DA5B01",
          })}
        />
      </FlexColumn>
    );
  };

  const TableCellTitle = ({ children, sx }) => {
    return (
      <TableCell
        sx={{
          border: "1px solid #ccc",
          fontWeight: "bold",
          color: "white",
          ...sx,
        }}
      >
        {children}
      </TableCell>
    );
  };

  const TableCellContent = ({ children, sx }) => {
    return (
      <TableCell sx={{ border: "1px solid #ccc", ...sx }}>
        <Text>{children}</Text>
      </TableCell>
    );
  };

  return (
    <Box className={styles.root} m={3}>
      <FlexColumn jcCenter aiCenter gap={5} className={styles.page}>
        <MainTitle title={"Dashboard"} />
        <FlexColumn gap={5}>
          <Text h3 colorTextSecondary alignCenter>
            Total de invitados confirmados
          </Text>
          <Text h3 colorTextSecondary alignCenter colorTextRed>
            {totalPasesUsados} de {totalPases}
          </Text>
        </FlexColumn>
        <FlexColumn gap={5}>
          <Text h3 colorTextSecondary alignCenter>
            Pases libres
          </Text>
          <Text h3 colorTextSecondary alignCenter colorTextRed>
            {totalRechazado}
          </Text>
        </FlexColumn>
        <FlexRow aiCenter jcCenter wrap>
          <ChartData
            label={"Invitados confirmados"}
            percentage={percentagePases}
          />
          <ChartData
            label={"Respuestas recibidas"}
            percentage={percentageRespondidos}
          />
        </FlexRow>

        <MainTitle title={"Todos los invitados"} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead sx={{ backgroundColor: "#DA5B01" }}>
              <TableRow>
                <TableCellTitle>#</TableCellTitle>
                <TableCellTitle>Invitados</TableCellTitle>
                <TableCellTitle>Confirma</TableCellTitle>
                <TableCellTitle>Pases Dados</TableCellTitle>
                <TableCellTitle>Pases Usados</TableCellTitle>
                <TableCellTitle>Comentarios</TableCellTitle>
              </TableRow>
            </TableHead>
            <TableBody>
              {allInvitados.map((row, index) => (
                <TableRow
                  key={row.id}
                  sx={{
                    backgroundColor: getBackGroundColor(index, row.confirma),
                  }}
                >
                  <TableCellContent>{index + 1}</TableCellContent>
                  <TableCellContent>{row.invitado_desc}</TableCellContent>
                  <TableCellContent>
                    {row.confirma === "1"
                      ? "Sí"
                      : row.confirma === "0"
                      ? "No"
                      : "Sin respuesta"}
                  </TableCellContent>
                  <TableCellContent>{row.pases}</TableCellContent>
                  <TableCellContent>{row.pases_usados}</TableCellContent>
                  <TableCellContent>{row.comentarios}</TableCellContent>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </FlexColumn>
    </Box>
  );
};

export default PageDashboard;
