import React, { useState, useEffect } from "react";
import { FlexColumn } from "../../../view/shared/base/FlexBox";
import PageContainer from "../../../view/shared/base/PageContainer";
import WeddingTitle from "./components/WeddingTitle";
import DeadLineCounter from "./components/DeadLineCounter";
import Gallery from "./components/Gallery";
import WhenAndWhere from "./components/WhenAndWhere";
import TimelineComponent from "./components/TimelineComponent";
import DressCode from "./components/DressCode";
import Blessed from "./components/Blessed";
import Parents from "./components/Parents";
import useInvitado from "../../../hooks/useInvitado";
import Hotel from "./components/Hotel";
import AnimatedSection from "../../../view/shared/base/AnimatedSection";
import Confirma from "./components/Confirma";
import Cards from "./components/Cards";
import LoadingIndicator from "../../../view/shared/base/LoadingIndicator";

const PageHome = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { invitado, getInvitado, confirmaAsistencia } = useInvitado();
  useEffect(() => {
    getInvitado();
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const arrayComponentsOne = [<Parents />, <Gallery />, <DressCode />];

  const arrayComponentsTwo = [
    <TimelineComponent />,
    <Cards invitado={invitado} />,
  ];

  if (isLoading) return <LoadingIndicator fullsize />;

  return (
    <FlexColumn
      sx={{
        width: "100%",
        backgroundPosition: "center",
        backgroundRepeat: "repeat",
      }}
    >
      <WeddingTitle />
      <DeadLineCounter show />
      <Blessed invitado={invitado} />
      <PageContainer>
        <WhenAndWhere />
      </PageContainer>
      <PageContainer>
        <FlexColumn jcCenter aiCenter>
          {arrayComponentsOne.map((item, index) => (
            <AnimatedSection key={index}>{item}</AnimatedSection>
          ))}
        </FlexColumn>
      </PageContainer>
      <AnimatedSection key={"348"}>
        <Hotel />
      </AnimatedSection>

      <PageContainer>
        <FlexColumn jcCenter aiCenter>
          {arrayComponentsTwo.map((item, index) => (
            <AnimatedSection key={index}>{item}</AnimatedSection>
          ))}
        </FlexColumn>
      </PageContainer>

      <Confirma
        show
        invitado={invitado}
        confirmaAsistencia={confirmaAsistencia}
      />
    </FlexColumn>
  );
};

export default PageHome;
