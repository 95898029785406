import React from "react";
import { FlexColumn } from "../../../../shared/base/FlexBox";
import Text from "../../../../shared/base/Text";
import MainTitle from "../../../../shared/base/MainTitle";
import BoxImage from "../../../../../view/shared/base/Image/BoxImage";
import codigo from "../../../../../../src/assets/images/codigo.png";
import PapperCardBase from "../../../../shared/base/PapperCardBase";

const DressCode = () => {
  return (
    <FlexColumn fullWidth fullHeight aiCenter jcCenter>
      <MainTitle title="Código de vestimenta" />
      <PapperCardBase>
        <Text xs_h2 md_h3 lg_h2 bold alignCenter colorTextSecondary>
          Formal
        </Text>
        <BoxImage
          mobile={codigo}
          desktop={codigo}
          sx={{
            width: "100%",

            borderRadius: "10px",
            aspectRatio: "1/1",
          }}
        />
        <Text xs_body1 md_h6 lg_h2 bold alignCenter colorTextSecondary>
          *Mujeres no beige, no blanco.
        </Text>
      </PapperCardBase>
    </FlexColumn>
  );
};
export default DressCode;
