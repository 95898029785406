import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames/bind";
import Text from "../Text";
import styles from "./styles.module.scss";
import Box from "@mui/material/Box";
import BoxImage from "../../../../view/shared/base/Image/BoxImage";
import logo from "../../../../../src/assets/images/logo_general.png";

const cx = classNames.bind(styles);

const LoadingIndicator = ({
  show = true,
  fixed = false,
  center = true,
  className,
  fullsize = true,
  background = true,
  extended = true,
  fullscreen = true,
  size,
  thickness,
  children = null,
  sx = {},
  ...props
}) =>
  show && (
    <Box
      data-testid="loading-indicator"
      className={cx(
        { center, fullscreen, fullsize, extended, background },
        className
      )}
      sx={{
        width: fullsize ? "100%" : "auto",
        Height: "100vh",
        position: fixed ? "fixed" : "static",
        ...sx,
      }}
      {...props}
    >
      <BoxImage
        mobile={logo}
        desktop={logo}
        sx={{
          height: 150,
        }}
      />
      <Text
        h1
        colorTextRed
        alignCenter
        className={styles.title}
        sx={{ fontSize: { xs: 40, md: 50, lg: 60 } }}
      >
        <CircularProgress color={"inherit"} size={size} thickness={thickness} />
        <br />
        Imelda y Néstor...
      </Text>

      {children}
    </Box>
  );

export default LoadingIndicator;
